
export default {
    name: 'BagCard',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        middleEmit: Object
    },
    data() {
        return {
            card: {
                visible: false,
                title: '',
                list: [],
            },
            bagCompo: {
                options: {
                    visible: false,
                },
                info: {},
            },
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.getCardAjax()
    },
    methods: {
        // 获取贺卡商品
        getCardAjax() {
            this.$api.product.getCardProduct({
                scene: 'cart',
                spuId: ''
            }).then(response => {
                const res = response.result || [];
                this.card.title = res[0]?.title
                this.card.list = res[0]?.items || []
                this.card.visible = !!this.card.list.length
                this.setCardData()
            })
        },
        setCardData() {
            this.card.list.forEach(p => {
                // 图片
                const firstPics = p.pics[0] || {};
                p.previewImg = firstPics.url
                p.displayMarketPrice = p.displaySalePrice
                if(p.skus.length < 2) {
                    const firstSku = p.skus[0] || {};
                    p.previewImg = firstSku.skuPic
                    p.displayMarketPrice = firstSku.displayMarketPrice
                }
            })
        },
        // 加购
        handleAdd(obj) {
            this.bagCompo.info = obj
            this.bagCompo.options.visible = true
        },
        emitAddConfirm() {
            // this.bagCompo.options.visible = false
        },
        emitAddSuccess() {
            this.middleEmit.fn('getBagAjax')
            this.bagCompo.options.visible = false
        },
        // 跳转
        jumpProduct(obj) {
            this.$router.push({
                name: 'Product-Seo',
                params: {
                    code: obj.productCode,
                    seoUrl: obj.seoUrl
                }
            })
        }
    },
}
