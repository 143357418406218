
import BagCustomizePopup from './BagCustomizePopup'
export default {
    name: 'BagCusRec',
    components: {
        BagCustomizePopup
    },
    asyncData({api, cookies, ...context}) {},
    props: {
        middleEmit: Object
    },
    data() {
        return {
            customizeCompo: {
                visible: false,
                url: '',
                on: {},
                height:'290px'
            },
            interaction: { // 交互参数
                visible: false,
                info: {}
            },
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.initialSet()
    },
    methods: {
        // 定制交互
        setCustomizeFn(){
            // 推荐交互
            this.customizeCompo.on = {
                setPopupInfo: obj => {
                    this.interaction.info = obj
                    this.$refs.custPopup?.showPopup(obj, true,2)
                },
                openPopup: (obj, callback) => {
                    callback({
                        params: this.interaction.info
                    })
                },
                onloadPopup: obj => {
                    this.interaction.visible = obj.hasCus
                },
                setCustomizeRecDone: obj => {
                    this.$refs.custPopup?.closePopup()
                    this.middleEmit.fn('getBagAjax')
                },
                closePopup:obj=>{
                    this.$refs.custPopup?.closePopup()
                }
            }
        },
        // 初始化
        initialSet() {
            let origin = location.origin;
            if(origin.includes('10.10.10')){
                origin = 'http://10.10.10.119:8080'
            }
            this.customizeCompo.url = `${origin}/custonIndex/recommand`
            this.customizeCompo.visible = true
            this.setCustomizeFn()
        }
    },
}
